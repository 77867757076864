<template>
	<div id="seedList">
		<page-seed-list :is-shop="0"></page-seed-list>
	</div>
</template>

<script>
import pageSeedList from '@/components/layout/land/page-seed-list.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
	export default{
		name:"SeedList",
		components:{
			pageSeedList
		},
		setup(){
			onBeforeRouteLeave((to,from,next)=>{
				let type = to.path == '/land/seedEdit' ? 1 : 2
				store.dispatch("setKeepPage",{name:from.name,type})
		    	next();
			})
		}
	}
</script>

<style lang="scss">
	
</style>
